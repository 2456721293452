import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Some of our areas of expertise",
    paragraph:
      "StackOverCode is a full stack consulting company, focused on finding practical and sensible solutions for any need, operation and plans to grow.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {/* Lightning fast workflow */}
                </div>
                <h3 className="mt-0 mb-12">Database Management</h3>
                
                  <ul>
                    <li>
                      Definition and management of MSSQL, MySQL, MariaDB,
                      PostgreSQL, Oracle; relational databases and NoSQL
                      implementations.
                    </li>
                    <li>
                      Troubleshooting, profiling, optimization and performance
                      tuning.
                    </li>
                    <li>
                      SQL programming of stored procedures, functions and views.
                    </li>
                    <li>Oracle APEX development</li>
                    <li>Data mining and extraction.</li>
                    <li>Data migrations, transformations and normalization.</li>
                    <li>Redundancy and backup management.</li>
                    <li>OLAP cubes</li>
                    <li>Dimensional modeling</li>
                  </ul>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/data-min.webp")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {/* Lightning fast workflow */}
                </div>
                <h3 className="mt-0 mb-12">
                  Server Management, Networking and Cloud Services
                </h3>
                {/* <p className="m-0"> */}
                  <ul>
                    <li>
                      Cloud architecture on AWS, GCP, Azure, DigitalOcean and
                      others.
                    </li>
                    <li>
                      Serverless implementations using workers, containers and
                      BaaS.
                    </li>
                    <li>
                      Deployment of unix and MS based servers or cloud
                      equivalents.
                    </li>
                    <li>
                      Server upgrades, cloud migration and cloud cost
                      optimization.
                    </li>
                    <li>
                      Set up, troubleshooting, optimization and performance
                      tuning.
                    </li>
                    <li>
                      Management of DNS, CDNs, VPNs, NAS, load balancers,
                      scalable storage, isolated servers and redundancy
                      deployments.
                    </li>
                    <li>
                      S3 file management, access control, processing and
                      lifecycles.
                    </li>
                    <li>Integration to new services.</li>
                  </ul>
                {/* </p> */}
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/monitoring-min.webp")}
                  alt="Features split 02"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {/* Lightning fast workflow */}
                </div>
                <h3 className="mt-0 mb-12">Software Development</h3>
                {/* <p className="m-0"> */}
                  <ul>
                    <li>Software architecture, planning and deployment.</li>
                    <li>Shell scripting and automation.</li>
                    <li>
                      PHP, Python, Javascript, C#, Java, React, Angular, Flutter
                      and others.
                    </li>
                    <li>IVR implementations</li>
                    <li>MVC implementations.</li>
                    <li>Git management, automation and auditing.</li>
                    <li>CI/CD implementations.</li>
                    <li>API integrations adn development.</li>
                  </ul>
                {/* </p> */}
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  src={require("./../../assets/images/development-min.webp")}
                  alt="Features split 03"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
