import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
// import Input from "../elements/Input";
// import { InlineWidget } from "react-calendly";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container" id="contact">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">Would you like to contact us?</h3>
            <h5>We have years of experience in tech and have worked on multiple projects out of our usual areas. In our work, change is optional but adapting to new technologies is mandatory for growth. <br />
If you have any questions or request for support out of our listed areas of work, please let us know and we will gladly check into it and help you find the best options.</h5>
          </div>
          <div>
          <ButtonGroup>
          <Button tag="a" color="primary" wideMobile href="https://calendly.com/mt-stackovercode/30min">
                    SCHEDULE A CALL
                    </Button></ButtonGroup>
            {/* <InlineWidget
              color="#00a2ff"
              text="Request a Demo"
              textColor="#ffffff"
              url="https://calendly.com/mt-stackovercode/30min?hide_event_type_details=1"
            /> */}
          </div>
          {/* <div className="cta-action">
            <Input id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your best email">
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg>
            </Input>
          </div> */}
          <div></div>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
